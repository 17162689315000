import { PerguntaType } from "../PerguntaContext/types";

export type QuestionarioType = {
    id_questionario: number;
    mensagem: string;
    descricao: string;
    empreendimentos_vinculados?: [];
    perguntas?: PerguntaQuestionarioType[];
    criado_por: number;
    oculto: number;
    ativo: string;
}

export type QuestionarioOportunidadeType = {
    id_questionario: number;
    descricao: string;
    mensagem: string;
    empreendimentos_vinculados?: [];
    perguntas?: PerguntaType[];
    questionario_publico: QuestionarioPublicoType[];
    questionario_publico_expirados?: QuestionarioPublicoType[];
    criado_por: number;
    oculto: number;
    ativo: string;
}

export type QuestionarioPublicoType = {
    id_questionario: number;
    id_oportunidade: number;
    contato: string;
    origem: string;
    id_envolvido: string;
    id_questionario_publico: number;
    link_publico: string;
    sla_expira: number;
    respostas_com_perguntas: RespostaQuestionarioPublicotype[];
    criado_em: string;
    nome_criado_por?: string;
    criado_por: number;
    oculto: number;
    ativo: string;
}

export type RespostaQuestionarioPublicotype = {
    atualizado_em: string;
    atualizado_por: number;
    criado_em: string;
    criado_por: string;
    id_pergunta: number;
    id_questionario_publico: number;
    pergunta: PerguntaType;
    resposta: any
}

export type PerguntaQuestionarioType = {
    id_questionario: number;
    id_pergunta: number;
    pergunta: PerguntaType;
    oculto: number;
    ativo: string;
}

export type RespostasQuestionarioType = {
    id_oportunidade: number;
    id_questionario: number;
    id_pergunta: number;
    resposta: [],
    criado_por: number;
    nome_atualizado_por: string;
    atualizado_em: string;
}

export type UltimaAtualizacaoQuestionarioType = {
    data: string;
    usuario: string;
}

export enum TipoCampoDinamico  {
    EMPREENDIMENTO,
    TIPOLOGIA,
    LOCALIZACAO,
    COMO_CONHECEU,
}

export type PermissoesQuestionarioOportunidadeType = {
    gerar: boolean,
    remover: boolean
}