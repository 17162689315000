import React, { useContext, useMemo, useState } from "react";
import "./index.css";
import { QuestionarioOportunidadeType } from "../../../../contexts/QuestionarioContext/types";
import { OportunidadeType } from "../../Cadastro/types";
import DommusToggle from "../../../../components/DommusToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { QuestionarioContext } from "../../../../contexts/QuestionarioContext";
import { isMobile, isTablet } from "react-device-detect";
import { GeracaoLinkQuestionarioPublico } from "./GeracaoLinkQuestionarioPublico";
import ModalDommus from "../../../../components/Modal";
import { Alert, Tabs } from "react-bootstrap";
import { DommusTab } from "../../../../components/DommusTab";
import { PerguntaItemVisualizacao } from "./PerguntaItemVisualizacao";
import { QuestionarioPublicoItem } from "./QuestionarioPublicoItem";

interface QuestionarioOportunidadadeItemProps {
  questionario: QuestionarioOportunidadeType;
  oportunidade: OportunidadeType;
}

export function QuestionarioOportunidadadeItem({
  questionario,
  oportunidade,
}: QuestionarioOportunidadadeItemProps) {
  const { ultimasAtualizacoesQuestionario, getUltimaAtualizacaoQuestionario, permissoesQuestionarioOportunidade  } = useContext(QuestionarioContext);
  const [abrirModalGerarLink, setAbrirModalGerarLink] = useState(false);

  const ultimaAtualizacao = useMemo(()=>{
    return getUltimaAtualizacaoQuestionario(questionario.id_questionario);
  },[ultimasAtualizacoesQuestionario])

  const icones = useMemo(()=>{
    return(
      isMobile && !isTablet ?
      <div className="atualizacao-section-mobile">        
        {permissoesQuestionarioOportunidade?.gerar && 
          <div>
            <button className="btn btn-gerar-link" onClick={()=>{setAbrirModalGerarLink(true)}}>
              <FontAwesomeIcon icon={faFileAlt}/>{"Gerar novo formulário de respostas"}
            </button>
          </div>        
        }
      </div>
      :
      <div className="atualizacao-section">
        {permissoesQuestionarioOportunidade?.gerar && 
          <div>
            <button className="btn btn-gerar-link" onClick={()=>{setAbrirModalGerarLink(true)}}>
              <FontAwesomeIcon icon={faFileAlt}/>{"Gerar novo formulário de respostas"}
            </button>
          </div>        
        }
      </div>
    );
  },[permissoesQuestionarioOportunidade])


  return (
    <>      
      <DommusToggle title={questionario.descricao} icones={(isMobile && !isTablet) ? <></> : icones}>
        <div className="mensagem-section">
          {questionario?.mensagem}
        </div>
        <Tabs >
          <DommusTab eventKey="perguntas_respostas" title="Perguntas/Respostas">
            <div className="perguntas-section">
              {questionario?.perguntas?.length ? 
                questionario?.perguntas?.map((pergunta, index)=>{
                return (
                  <PerguntaItemVisualizacao key={index} pergunta={pergunta}/>
                )
              }) : <Alert variant="warning">Nenhuma pergunta encontrada</Alert>}
            </div>
          </DommusTab>
          <DommusTab eventKey="avaliacao_opiniao" title="Avaliação/Opinião">
            <div className="publico-section">
              {questionario?.questionario_publico?.length ? 
                questionario?.questionario_publico?.map((questionarioPublico, index)=>{
                return (
                  <QuestionarioPublicoItem key={index} questionarioPublico={questionarioPublico}/>
                )
              }) : <Alert variant="warning">Nenhum questionário público gerado!</Alert>}
            </div>
          </DommusTab>
          <DommusTab eventKey="expirados" title="Expirados">
            <div className="publico-section">
              {questionario?.questionario_publico_expirados?.length ? 
                questionario?.questionario_publico_expirados?.map((questionarioPublico, index)=>{
                return (
                  <QuestionarioPublicoItem key={index} questionarioPublico={questionarioPublico} expirado={true}/>
                )
              }) : <Alert variant="warning">Nenhum questionário público gerado!</Alert>}
            </div>
          </DommusTab>
        </Tabs>       
        </DommusToggle>
      <ModalDommus
        size={"md"}
        open={abrirModalGerarLink}
        close={() => setAbrirModalGerarLink(false)}          
        titulo="Geração formulário de respostas"
      >
        <GeracaoLinkQuestionarioPublico
          oportunidade={oportunidade}
          questionario={questionario}
        
        />
      </ModalDommus>
    </>
  );
}
