import React, { useCallback, useContext, useMemo } from "react";
import "./index.css";
import { QuestionarioPublicoType } from "../../../../../contexts/QuestionarioContext/types";
import DommusToggle from "../../../../../components/DommusToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { RespostaPublicaItem } from "./RespostaPublicaItem";
import stringHelper from "../../../../../helpers/StringHelper";
import { QuestionarioContext } from "../../../../../contexts/QuestionarioContext";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../services/QuestionarioService";

interface QuestionarioPublicoItemProps {
    questionarioPublico: QuestionarioPublicoType;
    expirado?: boolean
}

export function QuestionarioPublicoItem({ questionarioPublico, expirado = false }: QuestionarioPublicoItemProps) {
    const {permissoesQuestionarioOportunidade, removerAvaliacaoOpiniao} = useContext(QuestionarioContext);

    const resolveBadgeStatus = useCallback(() => {
        if (questionarioPublico?.respostas_com_perguntas && questionarioPublico?.respostas_com_perguntas.length) {
            return <span className="badge-resposta badge-respondido">Respondido em: {moment(questionarioPublico?.respostas_com_perguntas[0].criado_em).format("DD/MM/YYYY HH:mm")}</span>
        } else {
            return <span className={`badge-resposta badge-${expirado ? 'expirado' : 'aberto'}`}>{expirado ? "Expirado em: " : "Expira em: "} {moment(questionarioPublico.criado_em).add(questionarioPublico.sla_expira, 'hours').format("DD/MM/YYYY hh:mm")}</span>
        }

    }, [questionarioPublico]);

    const removerQuestionarioPublico = ()=>{
        Swal.fire({
            title: 'Remoção de Formulário de respostas',
            text: `Tem certeza que deseja remover o formulário de respostas`,
            icon: 'question',
            confirmButtonText: 'Sim, remover.',
            showCancelButton: true,
            cancelButtonText: 'Não, Cancelar.'
        }).then(result => {
            if(result.isConfirmed) {
                trackPromise(QuestionarioService.removerQuestionarioPublico(questionarioPublico.id_questionario_publico)).then((response)=>{
                    removerAvaliacaoOpiniao(questionarioPublico.id_questionario, questionarioPublico.id_questionario_publico, expirado);
                }).catch((error)=>{
                    Swal.fire({
                        titleText: "Ooops...",
                        text: error?.response?.data?.message ?? `Ocorreu um erro ao remover formulário de respostas.${error}`,
                        icon: 'error'
                    });
                })
            }
        })

    }

    const icones = useMemo(() => {
        return (
            <div className="icons-section">
                <div>
                    {resolveBadgeStatus()}
                </div>
                <div>
                    <span>{`Criado em: ${moment(questionarioPublico.criado_em).format('DD/MM/YYYY')} | ${questionarioPublico?.nome_criado_por ?? "---"}`}</span>
                    {!expirado &&
                        <OverlayTrigger
                            key="iconCopy"
                            placement="top"
                            overlay={
                                <Tooltip
                                    id={`link-publico-copiar-${questionarioPublico.id_questionario_publico}`}
                                >
                                    Copiar
                                </Tooltip>
                            }
                        >
                            <button className="btn btn-link" onClick={() => { stringHelper.copyBoard(questionarioPublico?.link_publico) }}>
                                <FontAwesomeIcon icon={faCopy} />Copiar link
                            </button>
                        </OverlayTrigger>
                    }
                    {permissoesQuestionarioOportunidade?.remover && 
                        <button className="btn btn-questionario-remover" onClick={() => {removerQuestionarioPublico()}}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </button>
                    }
                </div>
            </div>
        )
    }, [questionarioPublico, permissoesQuestionarioOportunidade])


    return (
        <DommusToggle title={""} icones={icones}>
            <div className="resposta-container">
                <div className="info-section">
                    <div className="dados">
                        <label>Origem:</label>
                        <span>{questionarioPublico?.origem ?? "---"}</span>
                    </div>
                    <div className="dados">
                        <label>Contato:</label>
                        <span>{questionarioPublico?.contato ?? "---"}</span>
                    </div>
                </div>
                <div className="respostas-section">                    
                    {questionarioPublico?.respostas_com_perguntas && questionarioPublico?.respostas_com_perguntas.length ?
                        questionarioPublico?.respostas_com_perguntas.map((resposta, index) => {
                            return (
                                <RespostaPublicaItem key={index} resposta={resposta} />
                            )
                        })
                        : expirado ? <></> : <Alert variant="warning">Formulário ainda não foi respondido</Alert>
                    }
                </div>
            </div>
        </DommusToggle>
    )
}