import React, { useContext, useRef, useState } from "react";
import "./index.css";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import DommusToggle from "../../../../../components/DommusToggle";
import { useForm } from "react-hook-form";
import { QuestionarioOportunidadeType } from "../../../../../contexts/QuestionarioContext/types";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../services/QuestionarioService";
import { swalErro } from "../../../../../helpers/DommusSwal";
import QRCode from "react-qr-code"
import * as htmlToImage from "html-to-image";
import stringHelper from "../../../../../helpers/StringHelper";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import { OportunidadeType } from "../../../Cadastro/types";
import { QuestionarioContext } from "../../../../../contexts/QuestionarioContext";

interface GeracaoLinkQuestionarioPublicoProps {
    questionario: QuestionarioOportunidadeType;
    oportunidade: OportunidadeType;
}

type EnvioEnvolvidoFormType = {
    id_meio_comunicacao: number;
    envolvidos: any
}

const configs = {
    selectSomeItems: "Selecione",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
};


export function GeracaoLinkQuestionarioPublico({ questionario, oportunidade }: GeracaoLinkQuestionarioPublicoProps) {
    const { handleSubmit, register, control } = useForm<EnvioEnvolvidoFormType>()
    const {adicionarAvalicaoOpiniao} = useContext(QuestionarioContext);
    const [linkAvulsoCriado, setLinkAvulsoCriado] = useState<string | null>(null);
    const [slaExpira, setSlaExpira] = useState(120);
    const qrCodeRef = useRef(null);

    const handleOnSubmit = (data: EnvioEnvolvidoFormType) => {
        // data
    }

    const handleGerarLinkAvulso = () => {
        Swal.fire({
            titleText: "Gerar link avulso",
            text: `Tem certeza que deseja criar o link público para o questionário "${questionario.descricao}"?`,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, gerar!",
            cancelButtonText: "Não",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((res) => {
            if (res.value) {
                const payload = {
                    id_oportunidade: oportunidade.id_oportunidade,
                    id_questionario: questionario.id_questionario,
                    sla_expira: slaExpira,
                    origem: "avulso"
                }

                trackPromise(QuestionarioService.gerarLinkPublico(payload)).then((response: any) => {
                    adicionarAvalicaoOpiniao(response?.data?.id_questionario, response?.data);
                    setLinkAvulsoCriado(response?.data?.link_publico ?? null);
                }).catch((error) => {
                    let mensagem = "";
                    if(error.response?.data?.message){
                        mensagem = error.response?.data?.message;
                    }else{
                        mensagem =     `Houve um erro ao tentar gerar link avulso. Status: ${error}`
                    }
                    swalErro(mensagem);
                })
            }
        });
    }

    const exportarQrCode = () => {
        if (qrCodeRef?.current) {
            htmlToImage
                .toPng(qrCodeRef?.current, { width: 800, height: 800 })
                .then(function (dataUrl) {
                    const link = document.createElement("a");
                    link.href = dataUrl;
                    link.download = "qr-code.png";
                    link.click();
                })
                .catch(function (error) {
                    console.error("Error generating QR code:", error);
                });
        }
    }

    return (
        <div>
            <Row>
                <Col sm={12}>
                    <DommusToggle title="Link Avulso" toggle={true}>
                        <div className="link-avulso-section">
                            {linkAvulsoCriado ?
                                <div className="link-avulso-gerado">
                                    <div className="qr-code-section">
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={linkAvulsoCriado}
                                            viewBox={`0 0 256 256`}
                                            ref={qrCodeRef}
                                        />
                                        <OverlayTrigger
                                            key="iconCopy"
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id={"qr-code-publico"}
                                                >
                                                    Clique para baixar
                                                </Tooltip>
                                            }
                                        >
                                            <div className="exportar-overflow" onClick={() => { exportarQrCode() }}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="link-section">
                                        <OverlayTrigger
                                            key="iconCopy"
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id={"link-publico"}
                                                >
                                                    {linkAvulsoCriado}
                                                </Tooltip>
                                            }
                                        >
                                            <span>
                                                {linkAvulsoCriado}
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            key="iconCopy"
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id={"link-publico-copiar"}
                                                >
                                                    Copiar
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon icon={faCopy} className="icon-copy" onClick={() => {
                                                stringHelper.copyBoard(linkAvulsoCriado)
                                            }} />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                : <></>
                            }
                            <Row className="align-items-center">
                                <Form.Group as={Col} md="6" sm="12">
                                    <Form.Label style={{ fontSize: "0.8rem", color: "grey" }}>SLA de Expiração: <DommusRequiredSpan /></Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            min={1}
                                            value={slaExpira}
                                            onBlur={(event: any) => {
                                                let valor = Number(event?.target?.value);
                                                if (valor <= 0) {
                                                    valor = 120;
                                                }
                                                setSlaExpira(valor)
                                            }}
                                            onChange={(event) => {
                                                let valor = Number(event?.target?.value);
                                                setSlaExpira(valor)
                                            }}
                                        />
                                        <InputGroup.Text>Horas</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                                <Col lg={6} sm={12} className="mt-lg-3">
                                    <button className="btn btn-tipo-link link-avulso" onClick={() => { handleGerarLinkAvulso() }}>
                                        <FontAwesomeIcon icon={faLink} /> Gerar link avulso
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </DommusToggle>
                </Col>
            </Row>
            {/* {oportunidade.envolvidos_oportunidade?.length ?
                <Row className="mt-1">
                    <Col sm={12}>
                        <DommusToggle title="Enviar para envolvidos">
                            <form onSubmit={handleSubmit(handleOnSubmit)} className="envio-envolvido-section">
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Meio Comunicação:</Form.Label>
                                    <Controller
                                        control={control}
                                        name="id_meio_comunicacao"
                                        defaultValue={[]}
                                        as={
                                            <Select
                                                placeholder="Selecione"
                                                options={
                                                    [
                                                        {
                                                            label: "E-mail",
                                                            value: "email"
                                                        },
                                                        {
                                                            label: "SMS",
                                                            value: "sms"
                                                        },
                                                        {
                                                            label: "Whatsapp",
                                                            value: "whatsapp"
                                                        },
                                                    ]
                                                }
                                                className="basic-multi-select"
                                            />
                                        }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Envolvido(s):</Form.Label>
                                    <Controller
                                        control={control}
                                        name={"envolvidos"}
                                        defaultValue={[]}
                                        as={
                                            <MultiSelect
                                                options={
                                                    oportunidade.envolvidos_oportunidade.map((envolvido) => {
                                                        return {
                                                            label: envolvido.envolvido.nome,
                                                            value: envolvido.id_envolvido
                                                        }
                                                    }) as Option[]
                                                }
                                                labelledBy=""
                                                value={[]}
                                                overrideStrings={configs}
                                            />
                                        }
                                    />
                                </Form.Group>
                                <button className="btn btn-success">
                                    <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                                </button>

                            </form>
                        </DommusToggle>
                    </Col>
                </Row>
                : <></>
            } */}
        </div>
    )
}